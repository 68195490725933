@import '../../styles/index';

.box {
  width: 100%;
  padding: 1rem 0.75rem;
  border-radius: $border-radius-default $border-radius-default 0 0;

  @include media-from('sm') {
    padding: 0.75rem;
    width: 30rem;
    border-radius: $border-radius-default;
  }
}
